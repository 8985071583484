import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import { ClientGridColumns } from "./ClientRateGridColumns";
import EditRates from "./EditRate";
import { STRUCTURE } from "./RateConstant";
import Rateservice from "./RateService";
import { SupplierGridColumns } from "./SupplierRateGridColumns";

const Rates = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

  let { rateNo } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [editID, setEditID] = useState("");
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/rate/create" || rateNo);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [fromDate, setFromDate] = useState(null);
  const [tillDate, setTillDate] = useState(null);

  const fetchData = async (filterUrl) => {
    if (fromDate) {
      filterUrl += `&fromDate=${encodeURIComponent(`${fromDate}`)}`;
    }
    if (tillDate) {
      filterUrl += `&tillDate=${encodeURIComponent(`${tillDate}`)}`;
    }
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await Rateservice.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [fromDate, tillDate, Rateservice.forSupplier])

  useEffect(() => {
    setEditID(rateNo)
  }, [rateNo])

  const validateData = (data_for_validation) => {
    if (!data_for_validation?.length) {
      return "No data provided";
    }
    for (let i = 0; i < data_for_validation.length; i++) {
      const d = data_for_validation[i];
      if (!d) {
        return "No item data provided";
      }
      if (d?.forB2C==true && !d.rateExtn?.termsNPolicy_id) {
        return "Payment terms not selected";
      }
    }
  }
  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData.name) {
    //   setHasErr(true)
    //   showError(t("Please enter all fields"));
    //   return;
    // }
    const updatedData = detailData.map(item => {
      return item;
    })

    if (Rateservice?.forSupplier) {
      const error_response = validateData(updatedData);
      if (error_response) {
        showError(t(error_response));
        return;
      }
    } else {
      const error_response = validateData(detailData);
      if (error_response) {
        showError(t(error_response));
        return;
      }
    }

    setDetailData(Rateservice?.forSupplier ? updatedData : detailData);
    setLoading(true);
    try {
      if (editID) {
        await Rateservice.edit(Rateservice?.forSupplier ? updatedData : detailData, editID);
        showMessage(t("Rate Updated successfully"));
      } else {
        const rate = await Rateservice.save(Rateservice?.forSupplier ? updatedData : detailData);
        showMessage(t("Rate saved successfully."));
        if (!insidePane) navigate(`/rate/edit/${rate?.data}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };




  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Rateservice.delete(id);
        setShowDetailPage(false);
        showMessage(t("Rate Area Deleted"));
        navigate(`/rate`);
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback((rate) => {
    return (
      <>
        {userContent?.rights?.includes(1902) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/rate/edit/${rate?.rateNo}`);
              setEditID(rate?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(1903) ?
          <DeleteLink
            onClick={(event) => onDelete(event, rate?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(rate?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])
  const toggleData = {
    module: [
      { label: t("Client Rates"), value: "forClient" },
      { label: t("Supplier Rates"), value: "forSupplier" },
      { label: t("Rate Name"), value: "rateName" },
      { label: t("Rate Area"), value: "rateArea" },
    ],
    toggleValue: Rateservice.forSupplier
      ? "forSupplier"
      : "forClient"
  }

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("rate");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])


  return (
    <>
      <Layout
        large
        toggleData={toggleData}
        showToggle
        compact
        showDateRange
        dateRangeClass={"ms-2"}
        fromDate={fromDate}
        tillDate={tillDate}
        setFromDate={setFromDate}
        setTillDate={setTillDate}
        onToggle={(selectedValue) => {
          if (selectedValue === "rateName") {
            navigate(`/rateName`);
          } else if (selectedValue === "rateArea") {
            navigate(`/rate-area`);
          } else if (selectedValue === "forClient") {
            Rateservice.onSupplierChange(false);
          } else if (selectedValue === "forSupplier") {
            Rateservice.onSupplierChange(true);
          }
        }}
        showRateAreaAndRateName
        hideAdd={!userContent?.rights?.includes(1901)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/rate");
          setEditID(null);
        }}
         
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/rate/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={Rateservice.page}
        rows={Rateservice.rows}
        total={Rateservice.total}
        fetch={Rateservice.fetch}
      >
        <Layout.OnAlert>
          {!SetupProgressService?.records?.setUpStatus?.['rate'] ? <Alert color="danger" role="alert">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Please populate various rate packages for your clients or client groups.
              </div>
              <div>
                <Button onClick={markCompletedFunc}>Mark Complete</Button>
              </div>
            </div>          </Alert> : <></>}
        </Layout.OnAlert>
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                {userContent?.rights?.includes(1903) ?
                <>
                  <DropdownItem>Bulk Delete</DropdownItem>
                  <div class="dropdown-divider"></div>
                  </>
                  : null}
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={Rateservice.records}
            total={Rateservice.total}
            uiPreference="Rate.grid"
            headers={!Rateservice?.forSupplier ? ClientGridColumns : SupplierGridColumns}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            page={Rateservice.page}
            rowsPerPage={Rateservice.rowsPerPage}
            onPaginationChange={Rateservice.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>
        <Layout.DetailPageTitle>


          {rateNo ?
            <div className="d-flex align-items-center">

              <div>{t("Edit Rate -")} </div>
              <div className="ms-2">{rateNo}</div>
            </div>
            : t("Add Rate")}

        </Layout.DetailPageTitle>
        <Layout.DetailPageBody>
          <EditRates
            forSupplier={Rateservice.forSupplier}
            editId={editID}
            onChange={(v) => {
              setDetailData(v);
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>
        <Layout.DetailPageFooter>
          {detailData?._id ? (
            <AuditLogDialog module="Rate" id={detailData?._id} />
          ) : null
          }
          {editID && userContent?.rights?.includes(1903) && detailData?._id ?
            (<DeleteButton loading={loading} onClick={(e) => onDelete(e, editID)} />)
            : null}
          {userContent?.rights?.includes(1902) ?
            <SaveButton loading={loading} onClick={onSave} />
            : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Rates);
