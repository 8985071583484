import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import {
  CardThreeBounce,
  Checkbox,
  ClientPoolSelect,
  ClientSelect,
  DateSelect,
  SelectField,
  SupplierSelect,
  TermSelect,
  TimeSelect,
  ToggleSelector
} from "../../components";
import RateAreaSelect from "../../components/Dropdowns/RateAreaSelect/RateAreaSelect";
import RateNameSelect from "../../components/Dropdowns/RateNameSelect/RateNameSelect";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { SELECTSTRUCTURE } from "./RateConstant";
import Rateservice from "./RateService";
import Rateitems from "./Rateitems";
import "./style.css";
import RateNameService from "../RateName/RateNameService";


const EditRates = ({ editId, onChange = () => { }, hasErr, forSupplier }) => {
  const { t } = useContext(I18nContext);
  const rateNameRef = useRef();
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [singleClient, setSingleClient] = useState(true);
  const [rates, setRates] = useState([]);
  const [rateItemlabels, setRateItemlabels] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [slabRate, setSlabRate] = useState(false);
  const [rateName, setRateName] = useState({});
  const { rateNo } = useParams();
  const filterUrl = "";
  const fetch = async (filterUrl) => {
    try {
      if (editId) {
        let rateList = await Rateservice.get(rateNo, forSupplier);
        if(rateList.length > 0 && rateList[0]?.client_id){
          setSingleClient(true)
        }
        else if(rateList.length > 0 && rateList[0]?.clientPools?.length>0){
          setSingleClient(false)
        }
        if (rateList.length > 0 && rateList[0]?.rateName_id) {
          let rateName = await RateNameService.get(rateList[0]?.rateName_id);
          if (rateName?.isSlabRate)
            setSlabRate(true)
        }
        setRates(rateList);
      } else {
        let rateList = [{}];
        let rateItemlabels = await Rateservice.fetchItemLabels();
        for (let i = 0; i < rateList?.length; i++) {
          let rate = rateList[i];
          rate.appliesOn = "PER_VEHICLE";
          rate.status = 0;
          for (let j = 0; j < rateItemlabels?.length; j++) {
            if (!rate.rateExtn) {
              rate.rateExtn = {};
              if (
                !rate.rateExtn.billingDuration ||
                !rate.rateExtn.nightCharge
              ) {
                rate.rateExtn.timeUnit = "Hr";
                rate.rateExtn.timeRounding = 1;
                rate.rateExtn.billingDuration = "ON_DEMAND";
                rate.rateExtn.nightCharge = false;
              }
            }
            if (!rate?.rateExtn.rateItems) {
              rate.rateExtn.rateItems = [];
            }
            if (
              !rate?.rateExtn?.rateItems.some(
                (r) => r.name === rateItemlabels[j]?.label
              )
            ) {
              rate?.rateExtn?.rateItems.push({
                name: rateItemlabels[j]?.value,
                value: 0,
              });
            }
          }
        }
        setRates(rateList);
        setRateItemlabels(rateItemlabels);
      }
    } catch (error) {
      showError(error);
    }
  };


  const deleteRateItems = async (index, slabIndex) => {
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        const editID = rates[index]?._id;
        const updatedRates = rates.filter((_, i) => i !== index);
        if (editID) {
          await Rateservice.delete(editID);
        }
        setRates(updatedRates);
        showMessage(t("Rate Area Deleted"));
      } catch (e) {
        showError(e);
      }
    }
  };

  const addRateItems = async (slabIndex) => {
    try {
      const tempRateItem = [];
      const rateItemLabels = await Rateservice.fetchItemLabels();

      for (let i = 0; i < rateItemLabels?.length; i++) {
        const itemName = rateItemLabels[i];
        const formattedItem = {
          name: typeof itemName === "string" ? itemName : itemName.value,
          value: 0,
        };

        tempRateItem.push(formattedItem);
      }

      setRates([
        ...rates,
        {
          ...rates[0],
          _id: undefined,
          version: 0,
          vehicleType: "",
          makeModel: "",
          slabIndex: Number(slabIndex),
          rateExtn: {
            ...rates[0]?.rateExtn, rateItems: tempRateItem, _id: undefined,
            version: 0
          },
        },
      ]);
    } catch (error) { }
  };

  const updateValue = (isInsideRateExtn, field, newValue, index, rateIndex) => {
    let ratesData = rates?.length ? rates : [{}];
    if (rates[0]?.length === 0) {
      for (let j = 0; j < rateItemlabels?.length; j++) {
        if (!ratesData[0].rateExtn) {
          ratesData[0].rateExtn = {};
        }
        if (!ratesData[0]?.rateExtn.rateItems) {
          ratesData[0].rateExtn.rateItems = [];
        }
        if (
          !ratesData[0]?.rateExtn?.rateItems.some(
            (r) => r.name === rateItemlabels[j]
          )
        ) {
          ratesData[0]?.rateExtn?.rateItems.push({
            name: rateItemlabels[j],
            value: 0,
          });
        }
      }
    }
    for (let i = 0; i < ratesData?.length; i++) {
      if (index === i) {
        if (field === "makeModel") {
          ratesData[i][field] = newValue.value;
          ratesData[i]["vehicleType"] = newValue.vehicleType;
        } else if (field === "vehicleType") {
          ratesData[i][field] = newValue.value;
          ratesData[i]["makeModel"] = newValue.defaultMakeModel;
        } else if (field === "billingDuration") {
          ratesData[i][field] = newValue
        }
        else {
          ratesData[i]["rateExtn"]["rateItems"][rateIndex]["value"] = newValue;
        }
      } else if ((index === null || index === undefined) && !isInsideRateExtn) {
        ratesData[i][field] = newValue;
      } else if ((index === null || index === undefined) && isInsideRateExtn) {
        if (!ratesData[i]["rateExtn"]) {
          ratesData[i]["rateExtn"] = {};
        }
        ratesData[i]["rateExtn"][field] = newValue;
      }
    }
    setRates([...ratesData]);
  };

  // const mapRateExtnCheckboxes = (rates, setRates) => {
  //   const checkboxes = [
  //     // { value: "isNonAC", label: "AC ?" },
  //     // { value: "compareTimeDist", label: "Compare Time Distance" },
  //     // { value: "garageToGarage", label: "Garage To Garage" },
  //     // { value: "returnFareApplicable", label: "Return Fare Applicable" },
  //     // { value: "fuelSurcharge", label: "Fuel Surcharge" },
  //     // { value: "tripExpenseNonTaxable", label: "Trip Expense Non Taxable" },
  //     // { value: "resetNextDay", label: "Reset Next Day" },
  //     // { value: "resetMidNight", label: "Reset Mid Night" },
  //     { value: "nightCharge", label: "Night Charge" },
  //   ];
  //   return checkboxes.map((checkboxName, index) => (
  //     <Checkbox
  //       key={index}
  //       placeholder={t(checkboxName?.label)}
  //       checked={rates[0]?.rateExtn?.[checkboxName?.value]}
  //       value={rates[0]?.rateExtn?.[checkboxName?.value]}
  //       required={true}
  //       label={t(checkboxName?.label)}
  //       onClick={() => { updateValue(true, checkboxName?.value, !rates[0]?.rateExtn?.[checkboxName?.value]) }}
  //       showErr={hasErr}
  //       type="text"
  //       className="col-6 col-sm-6 col-lg-2"
  //     />
  //   ));
  // };
  useEffect(() => {
    // setRates([])
    fetch(filterUrl);
  }, [filterUrl, forSupplier]);

  useEffect(() => {
    setRates([]);
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, Rateservice?.version]);

  useEffect(() => {

    if (editId &&
      (
        rates?.validFrom ||
        rates?.validTill ||
        rates?.rateExtn?.timeRounding ||
        rates?.rateExtn?.nightStartTime ||
        rates?.rateExtn?.nightEndTime ||
        rates?.rateExtn?.nightCharge !== false) &&
      rates?.rateExtn?.billingDuration !== "ON_DEMAND") {
      setShowAdvancedOptions(true);
    }
    onChange(rates);
  }, [editId, rates]);



  useEffect(() => {
    onChange(rates);
  }, [rates]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rates.length > 0 && rates[0]?.rateName_id) {
          let rateName = await RateNameService.get(rates[0]?.rateName_id);
          setRateName(rateName);
          rateNameRef.current = rateName;
        }

        if (slabRate && !editId) {
          const updatedRates = []; // Start with an empty array

          await Promise.all(
            rateNameRef.current?.slabs?.map(async (item, idx) => {
              let rateList = [{}];
              let rateItemlabels = await Rateservice.fetchItemLabels();
              for (let i = 0; i < rateList?.length; i++) {
                let rate = rateList[i];
                rate.appliesOn = "PER_VEHICLE";
                rate.status = 0;
                rate.slabIndex = Number(item?.index); 
                if (rates[0]?.rateName_id) {
                  rate.rateName_id = rates[0]?.rateName_id;
                }
                for (let j = 0; j < rateItemlabels?.length; j++) {
                  if (!rate.rateExtn) {
                    rate.rateExtn = {};
                    if (
                      !rate.rateExtn.billingDuration ||
                      !rate.rateExtn.nightCharge
                    ) {
                      rate.rateExtn.timeUnit = "Hr";
                      rate.rateExtn.timeRounding = 1;
                      rate.rateExtn.billingDuration = "ON_DEMAND";
                      rate.rateExtn.nightCharge = false;
                    }
                  }
                  if (!rate?.rateExtn.rateItems) {
                    rate.rateExtn.rateItems = [];
                  }
                  if (
                    !rate?.rateExtn?.rateItems.some(
                      (r) => r.name === rateItemlabels[j]?.label
                    )
                  ) {
                    rate?.rateExtn?.rateItems.push({
                      name: rateItemlabels[j]?.value,
                      value: 0,
                    });
                  }
                }
              }
              updatedRates.push(...rateList);
            })
          );

          setRates(updatedRates);
        }
      } catch (error) {
        showError(error);
      }
    };
    if (rates[0]?.rateName_id)
      fetchData();
  }, [rates[0]?.rateName_id, editId]);

  if (loading) return <CardThreeBounce />;
  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Card className="p-3 mx-2 mt-4">
              <Col className="d-flex col-md-6 col-12">
                <Checkbox
                  placeholder={t("Slab Wise Rate")}
                  checked={!!slabRate}
                  value={slabRate}
                  required={true}
                  label={t("Slab Wise Rate")}
                  onClick={(v) => {
                    setSlabRate(
                      !slabRate
                    );
                  }}
                  showErr={hasErr}
                  type="text"
                  className="col-12 col-md-4  mt-2"
                />
                {/* {forSupplier === false ?
                  <Checkbox
                    placeholder={t("Single Client")}
                    checked={singleClient}
                    value={singleClient}
                    className="col-md-4 col-12"
                    label={t("Single Client")}
                    onClick={() => {
                      setSingleClient(!singleClient)
                    }}
                    showErr={hasErr}
                    type="text"
                  /> : null
                } */}
              </Col>
            <Row className="my-2">

              <Col className="col-md-6 col-lg-4 col-sm-12 ">
                <RateNameSelect
                  required={true}
                  placeholder={"Select Rate Name"}
                  label={"Rate Name"}
                  value={rates[0]?.rateName_id}
                  onChange={(v) => {
                    updateValue(false, "rateName_id", v?.value);
                  }}
                  isSlabRate={slabRate}
                />
              </Col>
              {rateName && Object.keys(rateName).length > 0 && <Col className="col-md-3 col-sm-12 col-lg-3 mt-3 col-12 d-flex justify-content-between">
                {rateName?.allotedKMs && <div class="font-size-13"><span style={{ color: "rgb(153, 153, 153)" }}>Alloted KMs : &nbsp;</span><span>{rateName?.allotedKMs + "KMs"}</span></div>}
                {rateName?.allotedMins && <div class="font-size-13"><span style={{ color: "rgb(153, 153, 153)" }}>Alloted Hours : &nbsp;</span><span>{(rateName?.allotedMins) / 60 + "Hrs"}</span></div>}
              </Col>}
              <Col className="col-md-3 col-sm-12 col-lg-2 mt-2 col-12 px-2">
                <Checkbox
                  placeholder={t("Status")}
                  checked={rates[0]?.status ? true : false}
                  value={rates[0]?.status ? true : false}
                  required={true}
                  label={t("Status")}
                  onClick={() => {
                    updateValue(
                      false,
                      "status",
                      rates[0]?.status === 0 || null ? 1 : 0
                    );
                  }}
                  showErr={hasErr}
                  type="text"
                />
              </Col>
              {!(forSupplier == true) && <Col className="col-sm-12 col-md-3  col-lg-2 mt-2 col-12">
                <Checkbox
                  placeholder={t("For B2C")}
                  checked={rates[0]?.forB2C}
                  value={rates[0]?.forB2C}
                  required={true}
                  label={t("For Retail")}
                  onClick={() => {
                    updateValue(false, "forB2C", !rates[0]?.forB2C);
                  }}
                  showErr={hasErr}
                  type="text"
                />
              </Col>}
              {forSupplier === true ? <Col className="col-md-3 col-sm-12 col-lg-4 mt-2 col-12 ">
                <Checkbox
                  placeholder={t("Supplier Rate")}
                  checked={rates[0]?.supplierRate ? true : false}
                  value={rates[0]?.supplierRate ? true : false}
                  required={true}
                  label={t("Supplier Rate")}
                  onClick={() => {
                    updateValue(
                      false,
                      "supplierRate",
                      rates[0]?.supplierRate === false || null ? true : false
                    );
                  }}
                  showErr={hasErr}
                  type="text"
                />
              </Col> : null}
              {/* <div className="d-flex flex-md-row flex-column justify-content-start">

            </div> */}
            </Row>

            <Row>
              {forSupplier === true ? (
                <SupplierSelect
                  // isDisabled={editId ? true : false}
                  value={rates[0]?.supplier_id}
                  required={false}
                  onChange={(v) => {
                    updateValue(false, "supplier_id", v?.value);
                  }}
                  className="col-12 col-sm-6 col-lg-4"
                />
              ) : null}
            </Row>
            <RateAreaSelect
              value={rates[0]?.rateAreas}
              multi
              onChange={(v) => {
                updateValue(false, "rateAreas", v);
              }}
              className="col-12 col-sm-6 col-lg-4"
            />

{   forSupplier === false ? <Col className="flex-1 col-10 ps-4">
                <ToggleSelector
                  data={[
                    { label: "Single Client", value: true },
                    { label: "Client Pool", value: false },
                  ]}
                  className="w-25"
                  style={{ width: "25%", position: "relative", top: "2.5px" }}
                  value={singleClient}
                  onChange={(v) => {
                    setSingleClient(!singleClient)
                  }}
                />

              </Col>:null}
            
              {forSupplier === false ? (
                singleClient ? (
                  <ClientSelect
                  value={rates[0]?.client_id}
                  onChange={(v) => {
                    updateValue(false, "client_id", v?.value);
                    updateValue(false, "clientPools", []);
                  }}
                  className="col-12 col-sm-6 col-lg-4 ms-0"
                  style={{ marginLeft: '40px', zIndex: '4' }}
                  />
                ) : (
                  <ClientPoolSelect
                    // isDisabled={editId ? true : false}
                    value={rates[0]?.clientPools}
                    multi
                    onChange={(v) => {
                      updateValue(false, "clientPools", v);
                      updateValue(false, "client_id", null);
                    }}
                    className="col-12 col-sm-6 col-lg-4"
                  />
                )
              ) : null}


            <SelectField
              data={SELECTSTRUCTURE?.fareType}
              value={rates[0]?.rateExtn?.fareType}
              onChange={(v) => {
                updateValue(true, "fareType", v?.value);
              }}
              error={
                rates[0]?.rateExtn?.fareType?.length === 0
                  ? "Please enter Garage Fare Type"
                  : ""
              }
              showErr={hasErr}
              label={t("Garage Fare Type")}
              placeholder={t("Garage Fare Type")}
              className="col-12 col-sm-6 col-lg-4"
            />
            <Row>
              {showAdvancedOptions && (
                <DateSelect
                  placeholder={t("ValidFrom")}
                  value={rates[0]?.validFrom}
                  label={t("Valid From")}
                  // timestamp
                  onChange={(v) => {
                    updateValue(false, "validFrom", v);
                  }}
                  error={
                    rates?.validFrom?.length === 0
                      ? "Please enter validFrom"
                      : ""
                  }
                  className="col-12 col-md-6 col-lg-3 "
                  showErr={hasErr}
                  type="text"
                />
              )}
              {showAdvancedOptions && (
                <DateSelect
                  placeholder={t("ValidTill")}
                  value={rates[0]?.validTill}
                  // timestamp
                  label={t("Valid Till")}
                  onChange={(v) => {
                    updateValue(false, "validTill", v);
                  }}
                  error={
                    rates?.rateNo?.length === 0
                      ? "Please enter validTill"
                      : ""
                  }
                  showErr={hasErr}
                  type="text"
                  className="col-12 col-md-6 col-lg-3"
                />)}

              {/* {showAdvancedOptions && (
                <SelectField
                  data={SELECTSTRUCTURE.timeUnitShort}
                  value={rates[0]?.rateExtn?.timeUnit}
                  onChange={(v) => {
                    updateValue(true, "timeUnit", v?.value);
                  }}
                  error={
                    rates[0]?.rateExtn?.timeUnit?.length === 0
                      ? "Please select Time Unit"
                      : ""
                  }
                  showErr={hasErr}
                  label={t("Time Unit")}
                  placeholder={t("Time Unit")}
                  className="col-12 col-sm-6 col-lg-3"
                />)} */}

              {showAdvancedOptions && (
                <Checkbox
                  placeholder={t("Exclude Toll/parking from taxation")}
                  checked={rates[0]?.rateExtn?.expensesNonTaxable ? true : false}
                  value={rates[0]?.rateExtn?.expensesNonTaxable ? true : false}
                  required={true}
                  label={t("Exclude Toll/parking from taxation")}
                  onClick={() =>
                    updateValue(
                      true,
                      "expensesNonTaxable",
                      !rates[0]?.rateExtn?.expensesNonTaxable
                    )
                  }
                  showErr={hasErr}
                  type="text"
                  className="col-12 col-sm-6 col-lg-2"
                />
              )}

              {showAdvancedOptions && (
                <SelectField
                  data={SELECTSTRUCTURE.rateCycles}
                  value={rates[0]?.rateExtn?.rateCycle}
                  onChange={(v) => {
                    updateValue(true, "rateCycle", v?.value);
                  }}
                  label={t("Day end type")}
                  placeholder={t("Day end type")}
                  className="col-12 col-sm-6 col-lg-4"
                />)}

              <TermSelect
                label="Payment Terms"
                className="col-md-4"
                required={rates[0]?.forB2C?true:false}
                value={rates[0]?.rateExtn?.termsNPolicy_id}
                onChange={(v) => {
                  updateValue(true, "termsNPolicy_id", v?.value);
                }
                }
              />

              {showAdvancedOptions && (
                <SelectField
                  data={SELECTSTRUCTURE.timeRounding}
                  value={rates[0]?.rateExtn?.timeRounding}
                  onChange={(v) => {
                    updateValue(true, "timeRounding", v?.value);
                  }}
                  error={
                    rates[0]?.rateExtn?.timeRounding?.length === 0
                      ? "Please enter Time Rounding"
                      : ""
                  }
                  showErr={hasErr}
                  label={t("Time Rounding")}
                  placeholder={t("Time Rounding")}
                  className="col-12 col-sm-6 col-lg-4"
                />)}

              <Row>
                {showAdvancedOptions && (
                  <Col className="col-3 mt-2 ">
                    <Checkbox
                      placeholder={t("Night charge")}
                      checked={rates[0]?.rateExtn?.nightCharge ? true : false}
                      value={rates[0]?.rateExtn?.nightCharge ? true : false}
                      required={true}
                      label={t("Night charge")}
                      onClick={() =>
                        updateValue(
                          true,
                          "nightCharge",
                          !rates[0]?.rateExtn?.nightCharge
                        )
                      }
                      showErr={hasErr}
                      type="text"
                      className="col-12 col-sm-6 col-lg-2"
                    />
                  </Col>)}
                {rates[0]?.rateExtn?.nightCharge ? <Col className="col-9">
                  <Row>
                    <TimeSelect
                      placeholder={t(`Enter Night Start Time`)}
                      label={t("Night Start Time")}
                      className="col-6 col-lg-4"
                      value={rates[0]?.rateExtn?.nightStartTime}
                      onChange={(v) => {
                        updateValue(true, "nightStartTime", Number(v));
                      }}
                    />
                    <TimeSelect
                      label={t("Night End Time")}
                      className="col-6 col-lg-4"
                      placeholder={t(`Enter Night End Time`)}
                      value={rates[0]?.rateExtn?.nightEndTime}
                      onChange={(v) => {
                        updateValue(true, "nightEndTime", Number(v));
                      }}
                    />
                  </Row>
                </Col> : <></>}

              </Row>
              {showAdvancedOptions && <div style={{ textAlign: "end", marginTop: "0px" }}>
                <a
                  href="#"
                  style={{
                    marginTop: "10px",
                    width: "250px",
                    backgroundColor: "white",
                    color: "#003399",
                    padding: "5px 10px",
                    border: "1px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    // position: 'fixed',
                    // right: '50px',
                    // top: "50%",
                    // top: '50%',
                    // transform: 'translateY(-50%)',
                  }}
                  onClick={() => {
                    setShowAdvancedOptions(!showAdvancedOptions);
                  }}
                >
                  Hide Advanced Options
                </a>
              </div>}
              {/* </Row> */}
              {/* )} */}

              {!showAdvancedOptions && (
                <div style={{ textAlign: "end", marginTop: "20px" }}>
                  <a
                    href="#"
                    style={{
                      width: "250px",
                      backgroundColor: "white",
                      color: "#003399",
                      padding: "5px 10px",
                      border: "1px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowAdvancedOptions(!showAdvancedOptions);
                    }}
                  >
                    Show Advanced Options
                  </a>
                </div>
              )}
            </Row>
          </Card>
          <Row
            div
            className="card p-3 mx-2 mt-4 overflow-x-auto"
            style={{ maxWidth: "calc(100vw - 70px)" }}
          >
            {/* <Card className="p-3 mx-2 mt-4" style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}> */}
            <Rateitems
              deleteRateItems={(index, slabIndex) => {
                deleteRateItems(index, slabIndex);
              }}
              addRateItems={(slabIndex) => addRateItems(slabIndex)}
              rates={rates ?? []}
              slabs={rateName?.slabs}
              slabRate={slabRate}
              onChange={({ field, value, index, rateIndex }) => {
                updateValue(
                  false,
                  field,
                  value,
                  index,
                  field === "vehicleType" || field === "makeModel"
                    ? -1
                    : rateIndex
                );
              }}
            />
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditRates);
