import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import SpaceService from '../SpaceService';
import FolderItem from './FolderItem';

const CreateSpace = ({ onClick }) => {
    return (<div
        style={{ /* backgroundColor: "#EBEBEB", */ borderRadius: "4px", padding: "4px" }}
        className="mt-3 d-flex align-items-center clickable cursor-pointer create-space-btn"
        onClick={onClick}>
        <FaPlus className="me-2" />
        <span className="font-size-14">Create Space</span>
    </div>)
}

const SpaceRenderer = ({
    spaces,
    module,
    useDropdown,
    setSpaceData,
    setModalOpen,
    setCurrentStep,
    handleStartCreatingSpace,
    isCollapsable, 
    onSpaceCollapse,
}) => {

    const [collapsedSpaces, setCollapsedSpaces] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const Wrapper = useDropdown ? DropdownItem : React.Fragment

    const toggleCollapse = (id) => {
        setCollapsedSpaces((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleManage = (v) => {
        setSpaceData({ ...v, spaceType: [2, 3, 4, 5]?.includes(v?.type) ? 'roster' : v?.type });
        setModalOpen(true);
        setCurrentStep(1);
    };

    const handleSpaceClick = (folder) => {
        // if (!folder?.parentSpace_id) {
        SpaceService.setSelectedFolder(folder, module);
        if (useDropdown && dropdownOpen) {
            toggleDropdown();
        }
        // }
    };

    const renderFolderItem = (folder, level = 0) => (
        <div key={folder._id} style={{ paddingLeft: `${level * 20}px` }}>
            <FolderItem
                folder={folder}
                onToggleCollapse={() => toggleCollapse(folder._id)}
                module={module}
                handleManage={handleManage}
                type={folder?.type}
                onSpaceClick={() => handleSpaceClick(folder)}
            />
            {!collapsedSpaces[folder._id] && folder.children && renderSpaces(folder.children, level + 1)}
        </div>
    );

    const renderSpaces = (spaces, level = 0) => {
        return spaces?.filter(space => true)?.map((folder) => {
            return (
                <React.Fragment key={folder._id}>
                    {renderFolderItem(folder, level)}
                </React.Fragment>
            );
        });
    };

    return (
        <div>
            {useDropdown ? (
                <div className='d-flex align-items-center'>
                    {isCollapsable ? <button type="button" className="btn px-1 btn-outline-secondary rounded-top-right-0 rounded-bottom-right-0" onClick={onSpaceCollapse}>
                        {<TbLayoutSidebarRightCollapse size={16} />}
                    </button> : null}

                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle style={isCollapsable ? {borderRadius : "0px 5px 5px 0px", borderLeft : "0px"} : {}} caret outline>
                            {SpaceService?.selectedFolder?.name ? SpaceService?.selectedFolder?.name : "Select Space"}
                        </DropdownToggle>
                        <DropdownMenu>
                            <div className='px-2'>
                                {renderSpaces(spaces)}
                                <CreateSpace onClick={handleStartCreatingSpace} />
                            </div>
                        </DropdownMenu>
                    </Dropdown>

                </div>

            ) : (
                <>
                    {renderSpaces(spaces)}
                    <CreateSpace onClick={handleStartCreatingSpace} />
                </>
            )}
        </div>
    );
};

export default observer(SpaceRenderer);
